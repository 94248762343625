<template>
  <div class="m-4">
    <h1>Register location admin</h1>
    <form class="login-form" @submit.prevent="handleSubmit">
      <div class="form-group">
        <input
          class="form-input grey-text"
          type="email"
          placeholder="Email"
          id="email"
          v-model="email"
          required
        />
      </div>
      <select v-model="locationSelected">
        <option
          v-for="location in locations"
          :value="location.name"
          :key="location.id"
        >
          {{ location.name }}
        </option>
      </select>
      <button class="btn btn-primary mt-2" type="submit">Create</button>
    </form>
  </div>
</template>

<script>
import {
  createLocationCode,
  registrationEmail,
  locations,
} from "@/services/location/location.js";
import { useToast } from "vue-toastification";

export default {
  name: "CreateUserLocation",
  data() {
    return {
      email: "",
      locations: ["Choose a location"],
      locationSelected: "",
    };
  },
  methods: {
    async handleSubmit() {
      try {
        this.toast.info("Sending user email...");

        const data = await createLocationCode(
          this.locationSelected,
          "location_admin"
        );

        this.sendRegistrationEmail(data.data.location_code);
      } catch (e) {
        this.toast.error(
          "Something went wrong creating the location code, please try again"
        );
      }
    },
    async sendRegistrationEmail(code) {
      try {
        const data = await registrationEmail(this.email, code);
        if (data.success) {
          this.toast.success(`The email was sent to ${this.email}`);
        }
      } catch (e) {
        this.toast.error(
          "Something went wrong sending the email, please try again"
        );
      }
    },
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  async created() {
    const data = await locations();
    this.locations = data.data.locations;
    console.log(this.locations);
  },
};
</script>

<template>
  <CreateLocationAdmin />
</template>

<script>
import CreateLocationAdmin from "@/components/users/createLocationAdmin.vue";

export default {
  name: "RegisterLocationDelegate",
  components: {
    CreateLocationAdmin,
  },
};
</script>
